import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storageSession from 'redux-persist/lib/storage/session'
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

import userReducer from './user.slice';
import gamesReducer from './games.slice';

const rootReducer = combineReducers({
    user: userReducer,
    games: gamesReducer,
})

const persistedReducer = persistReducer( {
  key: 'epistolio-can-write-you',
  storage: storageSession,
}, rootReducer )

export const store = configureStore( {
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
} );

export const persistor = persistStore(store)