import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams, Link } from "react-router-dom";
import { useState } from "react";
import { Button, Spinner, Modal, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle  } from '@fortawesome/free-solid-svg-icons';

import { setGames } from "../state/user.slice";
import CONFIG from '../config/config.json';

export default function BrowseGame (){
    let params = useParams();
    const dispatch = useDispatch();
    const games = useSelector( state => state.games.list );
    const userGames = useSelector( state => state.user.games );
    const token = sessionStorage.getItem( CONFIG.tokenKey );

    const [ errors, setErrors ] = useState( [] );
    const [ isLoading, setIsLoading ] = useState( false );
    const [ open, setOpen ] = useState( false );
    const [ newId, setNewId ] = useState( null );

    const game = games.find(g=>g.id == params.id);
    if ( !game ) {
        return ( <Navigate to="/games" />)
    }

    function _send() {
        
        setOpen(true);
        setIsLoading(true);

        setTimeout(()=> {
            setIsLoading( false );
            const newGames = JSON.parse( JSON.stringify( [...userGames] ) );
            const id = Math.random()*1000000;
            newGames.push({
                id: id,
                title: game.title,
                game: game.id,
                turn: false
            });

            setNewId(id);

            dispatch( setGames( { games: newGames } ) );
        }, 1000);

        return;

        // TODO: wire to API

        const body = new FormData();
        body.append( 'game', game.id );

        fetch( `${CONFIG.baseURI}/${CONFIG.userGamesEndpoint.replace(':id', token)}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: body
        } )
            .then( res => res.json() )
            .then( res => {
                dispatch( setGames( { games: res.games } ) );
                setNewId( res.id );
            } )
            .catch( er => {
                console.log(er);
                setErrors(['There was an error. Please try again.']);
            })
            .finally(() => {
                setIsLoading( false );
            })
    }
    
    return (
        <>
            <h1 className="h2 d-flex border-bottom mt-2 mb-2 pb-3 pt-3">
                {game.title}
            </h1>
            <h2 className="h5 mt-3 pt-3">About this Game:</h2>
            <div className="game-description" dangerouslySetInnerHTML={{__html: game.description}} />
            <Modal show={open}>

                <Modal.Body>
                    { isLoading ? 
                        <Spinner animation="border" role="status" className="ms-auto me-auto d-block mt-3 mb-3">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner> :
                        <div>
                            <h4 className="h4">Success!</h4>
                            <p>Your game is ready!</p>
                            <Link className="btn-secondary btn" to={`/games/${newId}`}>Go to Game</Link>
                        </div>
                    }
                </Modal.Body>
            </Modal>
           <Button active variant={'primary'} className={'mt-3'} onClick={_send}>Start! <FontAwesomeIcon icon={faPlayCircle} className='ms-1' /></Button>
        </>
    );
}