import { createSlice } from '@reduxjs/toolkit';

const _initialState = {
    id: null,
    games: [],
    name: '',
    email: '',
    address: {
        address1: "",
        address2: '',
        city: '',
        state: '',
        zip: ''
    }
};

export const userSlice = createSlice({
  name: 'user',
  initialState: JSON.parse( JSON.stringify( _initialState ) ),
  reducers: {
    setUser: (state, action) => {
      state.id = action.payload.id;
      state.email = action.payload.email;
      state.name = action.payload.name;
      state.address = JSON.parse(JSON.stringify( {...action.payload.address}) );
    },
    setGames: (state, action) => {
      state.games = action.payload.games
    },
    updateAddress: (state, action) => {
        state.name = action.payload.name;
        state.address = {...action.payload.address};
    },
    updateEmail: (state, action) => {
        state.email = action.payload.email;
    },
    resetUser: (state, action) => {
        state.id = _initialState.id;
        state.name = _initialState.name;
        state.email = _initialState.email;
        state.address = JSON.parse(JSON.stringify(_initialState.address));
    }
  },
})

// Action creators are generated for each case reducer function
export const { setUser, setGames, updateAddress, updateEmail, resetUser } = userSlice.actions

export default userSlice.reducer