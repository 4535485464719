import { Modal, Button, Form, Spinner } from "react-bootstrap"
import React, { useState } from 'react';

import CONFIG from '../config/config.json';

export default function Login({onLogin}){

    const [email, setEmail] = useState( '' );
    const [password, setPassword] = useState( '' );
    const [errors, setErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    function _login() {
        if ( isLoading ) {
            return;
        }

        if ( !email ) {
            return setErrors(['Email address is required.']);
        }

        if ( !password ) {
            return setErrors(['Password is required.']);
        }

        setIsLoading(true);

        // try login

        fetch(`${CONFIG.baseURI}/${CONFIG.loginEndpoint}`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify( { email, password } ),
            redirect: 'follow'
        })
        .then( res => res.json() )
        .then( res => {
            if ( !res.data || ! res.data.user || !res.data.user.id || ! res.data.token ) {
                throw new Error('Incorrect email or password')
            }

            let newUser = {...res.data.user};
            let newToken = res.data.token;

            fetch(`${CONFIG.baseURI}/${CONFIG.userEndpoint}`, {
                headers: {
                    "Epistolio-Token": res.data.token
                }
            })
            .then ( res=>res.json() )
            .then ( res=> {
                newUser = {...res.data.user};
            } )
            .finally(() => onLogin( {user: newUser, token: newToken } ) );
        } )
        .catch(ex => {
            console.log( `Error logging in: ${ex}` );
            setIsLoading(false);
            return setErrors(['Your email address or password did not match. Please try again.']);
        })
    }

    return (
        <div className="login">
            <Modal show={true}>
                <Modal.Header>
                    <Modal.Title>Login</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    { isLoading ? 
                        <Spinner animation="border" role="status" className="ms-auto me-auto d-block mt-3 mb-3">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner> : 
                        <Form>
                            <Form.Group className="mb-3" controlId="loginEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" placeholder="Enter email" value={email} onChange={e=>setEmail(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="loginPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="Enter password" value={password} onChange={e=>setPassword(e.target.value)} />
                            </Form.Group>
                        </Form>
                    }
                    {
                        !errors.length ? '' : errors.map( ( e, id ) => <div className="alert" key={id}>{e}</div> )
                    }
                </Modal.Body>

                <Modal.Footer>
                    <Button type="submit" variant="primary" onClick={e=>_login()}>Log In</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}