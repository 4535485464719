import './App.scss';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Nav, Navbar, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPuzzlePiece, faGamepad, faCog  } from '@fortawesome/free-solid-svg-icons';
import { NavLink, Route, Routes, Navigate, Link } from 'react-router-dom';


import Login from './Login';
import Games from './Games';
import Browse from './Browse';
import Settings from './Settings';
import Game from './Game';

import CONFIG from '../config/config.json';

import { setUser, resetUser, setGames } from '../state/user.slice';
import BrowseGame from './BrowseGame';

function App() {
    const dispatch = useDispatch();
    const userId = useSelector(state=>state.user.id);

    function _onLogin({user, token}) {
        if( !user || !user.id || !token ) {
            return;
        }

        fetch( `${CONFIG.baseURI}/${CONFIG.userGamesEndpoint}`, {
            method: 'GET',
            headers: {
                'Epistolio-Token': token
            }
        })
        .then( res => res.json() )
        .then( res => dispatch( setGames( {games: res.data.games } ) ) )
        .finally( () => 
            setTimeout( () => {
                dispatch( setUser( {...user} ) ); 
                sessionStorage.setItem( CONFIG.tokenKey, token );
            }, 1000 ) 
        )
    }

    function _logout() {
        dispatch(resetUser());
    }

    return (
        <div className="App">
            {
                !userId ? <Login onLogin={_onLogin} /> :
                <main className='main'>
                    <Navbar bg="dark" variant="dark" className='sticky-top flex-md-nowrap p-0 shadow'>
                        <Navbar.Brand className='col-md-3 col-lg-2 me-0 px-3'>
                            <Link to="/" style={{color: 'white', textDecoration: 'none'}}>Epistol.io</Link>
                        </Navbar.Brand>
                        <Nav className='ms-auto'>
                            <Nav.Link onClick={e=>_logout()}>Logout</Nav.Link>
                        </Nav>
                    </Navbar>
                    <Container fluid>
                        <Row>
                            <nav className='col-md-3 col-lg-2 d-md-block bg-light sidebar collapse'>
                                <div className='position-sticky pt-3'>
                                    <ul className='nav flex-column'>
                                        <li className='nav-item'>
                                            <NavLink to="/games" className={ ({isActive}) => `nav-link ${isActive ? 'active' : ''}` }>
                                                <FontAwesomeIcon icon={faPuzzlePiece} fixedWidth className='me-2' />
                                                My Games
                                            </NavLink>
                                            <NavLink to="/browse" className={ ({isActive}) => `nav-link ${isActive ? 'active' : ''}` }>
                                                <FontAwesomeIcon icon={faGamepad} fixedWidth className='me-2' />
                                                Browse Games
                                            </NavLink>
                                            <NavLink to="/settings" className={ ({isActive}) => `nav-link ${isActive ? 'active' : ''}` }>
                                                <FontAwesomeIcon icon={faCog} fixedWidth className='me-2' />
                                                Settings
                                            </NavLink>
                                        </li>
                                    </ul>
                                </div>
                                <div className='col-lg-2 col-md-3' style={{ position: 'fixed', bottom: 0, left: 0, padding: 10, textAlign: 'center'}}>
                                    &copy; {(new Date()).getFullYear()} Glorn Weller Enterprises
                                    <a href="/" style={{display: 'block'}}>glornweller.io</a>
                                </div>
                            </nav>
                            <div className='col-md-9 ms-sm-auto col-lg-10 px-md-4'>
                                <Routes>
                                    <Route path="games" element={<Games />} />
                                    <Route path="games/:id" element={<Game />} />
                                    <Route path="browse" element={<Browse />} />
                                    <Route path="browse/:id" element={<BrowseGame />} />
                                    <Route path="settings" element={<Settings />} />
                                    <Route path="*" element={<Navigate to="/games" replace />} />
                                </Routes>
                            </div>
                        </Row>
                    </Container>
                </main>
            }
        </div>
    );
}

export default App;
