import { useEffect, useState } from "react";
import { Form, Button, Col, Row, Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../state/user.slice";

import CONFIG from '../config/config.json';

export default function Settings (){
    const user = useSelector( state => state.user );
    const token = sessionStorage.getItem( CONFIG.tokenKey );
    const dispatch = useDispatch();

    const [email, setEmail] = useState( user.email || '' );
    const [password, setPassword] = useState('');

    const [name, setName] = useState( user.name || '' );
    const [address1, setAddress] = useState( user.address?.address1 || '');
    const [address2, setAddress2] = useState( user.address?.address2 || '');
    const [city, setCity] = useState( user.address?.city || '');
    const [state, setState] = useState( user.address?.state || '');
    const [zip, setZip] = useState( user.address?.zip || '');

    const [open, setOpen] = useState( false );
    const [isLoading, setIsLoading] = useState( false );
    const [errors, setErrors] = useState( [] ); 

    function _updateProfile(e) {
        e.preventDefault();
        setOpen( true );
        setIsLoading( true );

        // send to API

        let updateRequest = { 
            email, 
            name, 
            address: {
                address1,
                address2,
                city,
                state,
                zip
            } 
        } ;

        if ( password ) {
            updateRequest.password = password;
        }
        
        fetch( `${CONFIG.baseURI}/${CONFIG.userEndpoint}`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                "Epistolio-Token": token
            },
            body: JSON.stringify( updateRequest )
        } )
            .then( res => res.json() )
            .then( res => {

                if ( ! res.data || !res.data.user || !res.data.user.id) {
                    throw new Error("Update could not be completed.");
                }

                dispatch( setUser( {...res.data.user } ) );
            } )
            .catch( er => {
                console.log( er );
                setErrors(['There was an error. Please try again.']);
            })
            .finally(() => {
                setIsLoading( false );
            })
    }

    function _close(e) {
        e.preventDefault();
        setOpen( false );
        setIsLoading( false );
    }

    return (
        <>
            <Modal show={open}>
                <Modal.Body>
                    { isLoading ? 
                        <Spinner animation="border" role="status" className="ms-auto me-auto d-block mt-3 mb-3">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner> :
                        <div>
                            <h4 className="h4">Success!</h4>
                            <p>Your information has been updated!</p>
                            <Button variant={'primary'} onClick={_close}>Close</Button>
                        </div>
                    }
                </Modal.Body>
            </Modal>
            <h1 className="h2 d-flex border-bottom mt-2 mb-2 pb-3 pt-3">Settings</h1>
            <h2 className="h5 mt-3 pt-3 pb-1 border-bottom">Profile:</h2>
            <Form>
                <Form.Group className="mb-3" controlId="loginEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" value={email} onChange={e=>setEmail(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="loginPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Enter password" value={password} onChange={e=>setPassword(e.target.value)} />
                </Form.Group>
            </Form>

            <h2 className="h5 mt-5 pt-3 pb-1 border-bottom">Address:</h2>
            <Form>
                <Form.Group className="mb-3" controlId="profileName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter name" value={name} onChange={e=>setName(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label htmlFor="profileAddress">Street</Form.Label>
                    <Form.Control type="text" id="profileAddress" placeholder="Enter street (123 Fake St.)" value={address1} onChange={e=>setAddress(e.target.value)} />
                    <Form.Control className="mt-2" id="profileAddress2" type="text" placeholder="Enter street (cont) (Ste. 2345)" value={address2} onChange={e=>setAddress2(e.target.value)} />
                </Form.Group>
                <Row>
                    <Col md={4} sm={6}>
                        <Form.Group className="mb-3" controlId="profileCity">
                            <Form.Label>City</Form.Label>
                            <Form.Control type="text" placeholder="Enter City (Lakeland)" value={city} onChange={e=>setCity(e.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col md={4} sm={6}>
                        <Form.Group className="mb-3" controlId="profileState">
                            <Form.Label>State</Form.Label>
                            
                            <Form.Select aria-label="Enter State (FL)" value={state} onChange={e=>setState(e.target.value)}>
                                <option></option>
                                <option value="AL">Alabama</option>
                            	<option value="AK">Alaska</option>
                            	<option value="AZ">Arizona</option>
                            	<option value="AR">Arkansas</option>
                            	<option value="CA">California</option>
                            	<option value="CO">Colorado</option>
                            	<option value="CT">Connecticut</option>
                            	<option value="DE">Delaware</option>
                            	<option value="DC">District Of Columbia</option>
                            	<option value="FL">Florida</option>
                            	<option value="GA">Georgia</option>
                            	<option value="HI">Hawaii</option>
                            	<option value="ID">Idaho</option>
                            	<option value="IL">Illinois</option>
                            	<option value="IN">Indiana</option>
                            	<option value="IA">Iowa</option>
                            	<option value="KS">Kansas</option>
                            	<option value="KY">Kentucky</option>
                            	<option value="LA">Louisiana</option>
                            	<option value="ME">Maine</option>
                            	<option value="MD">Maryland</option>
                            	<option value="MA">Massachusetts</option>
                            	<option value="MI">Michigan</option>
                            	<option value="MN">Minnesota</option>
                            	<option value="MS">Mississippi</option>
                            	<option value="MO">Missouri</option>
                            	<option value="MT">Montana</option>
                            	<option value="NE">Nebraska</option>
                            	<option value="NV">Nevada</option>
                            	<option value="NH">New Hampshire</option>
                            	<option value="NJ">New Jersey</option>
                            	<option value="NM">New Mexico</option>
                            	<option value="NY">New York</option>
                            	<option value="NC">North Carolina</option>
                            	<option value="ND">North Dakota</option>
                            	<option value="OH">Ohio</option>
                            	<option value="OK">Oklahoma</option>
                            	<option value="OR">Oregon</option>
                            	<option value="PA">Pennsylvania</option>
                            	<option value="RI">Rhode Island</option>
                            	<option value="SC">South Carolina</option>
                            	<option value="SD">South Dakota</option>
                            	<option value="TN">Tennessee</option>
                            	<option value="TX">Texas</option>
                            	<option value="UT">Utah</option>
                            	<option value="VT">Vermont</option>
                            	<option value="VA">Virginia</option>
                            	<option value="WA">Washington</option>
                            	<option value="WV">West Virginia</option>
                            	<option value="WI">Wisconsin</option>
                            	<option value="WY">Wyoming</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md={4} sm={12}>
                        <Form.Group className="mb-3" controlId="profileZip">
                            <Form.Label>Zip</Form.Label>
                            <Form.Control type="text" placeholder="Enter Zip (33813)" value={zip} onChange={e=>setZip(e.target.value)} />
                        </Form.Group>
                    </Col>
                </Row>
                <Button type="submit" variant="primary" onClick={_updateProfile}>Update Profile</Button>
            </Form>
            <h3 className="h5 mt-5 pt-3 pb-1 border-bottom">Payment:</h3>
            <p className="mb-5">Coming soon.</p>
        </>
    );
}