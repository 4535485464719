import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Card, Spinner, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import CONFIG from '../config/config.json';
import { setGames } from "../state/games.slice";

export default function Browse() {
    const token = sessionStorage.getItem( CONFIG.tokenKey );
    const games = useSelector( state => state.games.list || [] );
    const dispatch = useDispatch();
    const [ isLoading, setIsLoading ] = useState( true );
    const [ errors, setErrors ] = useState( [] );

    useEffect( () => {

        if ( games.length) {
            setIsLoading( false );
            return;
        }

        if ( !games.length ) {
            dispatch( setGames( { games: [ {
                id: 12345,
                title: 'A game',
                shortDescription: "Camembert de normandie stilton fromage.",
                description: "<p>Camembert de normandie stilton fromage. Cheesy feet brie taleggio say cheese airedale dolcelatte smelly cheese dolcelatte. Stinking bishop goat hard cheese when the cheese comes out everybody's happy brie camembert de normandie babybel melted cheese. Swiss gouda fondue cottage cheese airedale cheesecake rubber cheese cauliflower cheese. Cheesy feet stinking bishop.</p><p>Airedale pepper jack gouda. Jarlsberg paneer gouda boursin fondue fromage frais cheeseburger macaroni cheese. Feta cheesy grin mascarpone pepper jack hard cheese cheese and biscuits feta airedale. Roquefort dolcelatte smelly cheese croque monsieur danish fontina fondue melted cheese roquefort. Chalk and cheese paneer halloumi.</p>"
            }, {
                id: 34567,
                title: 'A game 2',
                shortDescription: "I'm baby locavore aesthetic jianbing sriracha kogi keytar trust fund.",
                description: "<p>I'm baby locavore aesthetic jianbing sriracha kogi keytar trust fund. Ennui dreamcatcher cred 90's bicycle rights lyft. Pop-up fixie snackwave, actually ugh hoodie listicle edison bulb selvage asymmetrical next level. Bushwick gochujang tousled cornhole kombucha viral next level. Swag health goth pitchfork yes plz, chillwave pinterest everyday carry pork belly echo park praxis coloring book bitters. Prism ugh echo park disrupt selvage authentic literally banjo sustainable tonx raw denim keytar salvia distillery.</p><p>Keffiyeh migas godard yr affogato. Stumptown tattooed trust fund, whatever woke VHS mlkshk banh mi tote bag enamel pin fingerstache DIY. Listicle franzen readymade mixtape. Twee dreamcatcher prism schlitz etsy, migas venmo hammock crucifix tofu listicle man bun XOXO locavore. VHS lyft af organic tacos 90's cardigan DIY yuccie hell of. Poke mlkshk DIY vape listicle brunch cliche. Man bun marfa snackwave occupy tousled authentic, dreamcatcher pour-over pop-up.</p><p>Dummy text? More like dummy thicc text, amirite?</p>"
            } ] } ) );
            
            setIsLoading( false );
            return;

            // TODO: wire to API

            fetch( `${CONFIG.baseURI}/${CONFIG.gamesEndpoint}` )
                .then( res => res.json() )
                .then( res => dispatch( setGames( res.games ) ) )
                .catch( er => {
                    console.log(er);
                    setErrors(['There was an error. Please try again.']);
                })
                .finally(() => {
                    setIsLoading( false );
                })
        }
    }, [] );

    return (
        <>
        <h1 className="h2 border-bottom mt-2 mb-2 pb-2 pt-3">Browse Games</h1>
        {
            isLoading ? <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner> :
            <div className="games">
                { 
                    !games.length ? <div className="">No games found!</div> :
                    <Row xs={1} md={2} lg={3} className="g-3">
                        { games.map( ( g, i ) => <Col key={i}> <Card>
                            <Card.Img variant="top" src={g.image || `https://via.placeholder.com/180x100?text=${encodeURIComponent(g.title)}`} />
                            <Card.Body>
                                <Card.Title>{g.title}</Card.Title>
                                <Card.Text>{g.shortDescription}</Card.Text>
                                <Link className="btn active btn-primary" to={`/browse/${g.id}`}>Start</Link>
                            </Card.Body>
                        </Card> </Col> ) }
                    </Row>
                } 
            </div>
        }
        </>
    )
}