import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Card, Button, Spinner, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import CONFIG from '../config/config.json';
import { setGames } from "../state/user.slice";

export default function Games() {
    const user = useSelector( state=>state.user );
    const token = sessionStorage.getItem( CONFIG.tokenKey );
    const games = useSelector( state => state.user.games || [] );
    const dispatch = useDispatch();
    const [ isLoading, setIsLoading ] = useState( true );
    const [ errors, setErrors ] = useState( [] );

    useEffect( () => {

        if ( games.length ) {
            return setIsLoading( false );
        }

        fetch( `${CONFIG.baseURI}/${CONFIG.userGamesEndpoint}`, {
            method: 'GET',
            headers: {
                'Epistolio-Token': token
            }
        })
        .then( res => res.json() )
        .then( res => dispatch( setGames( {games: res.data.games } ) ) )
        .catch( er => {
            console.log(er);
            setErrors(['There was an error. Please try again.']);
        })
        .finally(() => {
            setIsLoading( false );
        })

    }, [] );

    return (
        <>
        <h1 className="h2 border-bottom mt-2 mb-2 pb-2 pt-3">My Current Games</h1>
        {
            isLoading ? <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner> :
            <div className="games">
                { 
                    !games.length ? <div className="">You have no active games, start a new one!</div> :
                    <Row xs={1} md={2} lg={3} className="g-3">
                        { games.map( ( g, i ) => <Col key={i}> <Card>
                            <Card.Img variant="top" src={g.image || `https://via.placeholder.com/180x100?text=${encodeURIComponent(g.title)}`} />
                            <Card.Body>
                                <Card.Title>{g.title}</Card.Title>
                                <Card.Text>{g.shortDescription}</Card.Text>
                                <Link className={`btn ${!!g.turn ? 'active' : ''} btn-${!!g.turn ? "primary" : 'outline-secondary'}`} to={`/games/${g.sessionId}`}>{ !!g.turn ? 'Play' : 'Waiting' }</Link>
                            </Card.Body>
                        </Card> </Col> ) }
                    </Row>
                } 
            </div>
        }
        </>
    )
}