import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
 import { Editor } from '@tinymce/tinymce-react';
import { useRef, useState } from "react";
import { Button, Spinner, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane  } from '@fortawesome/free-solid-svg-icons';

import CONFIG from '../config/config.json';
import { setGames } from "../state/user.slice";

export default function Game (){
    let params = useParams();

    const editorRef = useRef(0)

    const dispatch = useDispatch();

    const games = useSelector( state => state.user.games );
    const token = sessionStorage.getItem( CONFIG.tokenKey );

    const [ isLoading, setIsLoading ] = useState( false );
    const [ open, setOpen ] = useState( false );
    const [ errors, setErrors ] = useState( [] );

    const game = games.find(g=>g.sessionId == params.id);
    if ( !game ) {
        return ( <Navigate to="/games" />)
    }

    function _send() {
        if (!editorRef.current) {
            return alert('There was an error. Please try again.');
        }
        
        setOpen(true);
        setIsLoading(true);

        // send to API

        const body = JSON.stringify( { content: editorRef.current.getContent() } );

        fetch( `${CONFIG.baseURI}/${CONFIG.letterEndpoint.replace(':gameid', game.sessionId)}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Epistolio-Token': token
            },
            body: body
        } )
            .then( res => res.json() )
            .then( res => {
                if ( ! res.data || !res.data.letter || !res.data.letter.id ) {
                    throw new Error( 'Did not retrieve success');
                }

                const newGames = JSON.parse( JSON.stringify( [...games] ) );
                const newGameIndex = newGames.map(g=>g.gameId).indexOf(game.gameId);
                newGames[newGameIndex].turn = false;

                dispatch( setGames( { games: newGames } ) );
            } )
            .catch( er => {
                console.log(er);
                setErrors(['There was an error. Please try again.']);
            })
            .finally(() => {
                setIsLoading( false );
            })
    }

    function _closeModal(e) {
        e.preventDefault();
        setOpen(false);
    }

    return (
        <>
            <h1 className="h2 d-flex border-bottom mt-2 mb-2 pb-3 pt-3">
                {game.title}
                {!!game.turn ? <div className="btn btn-primary ms-auto">Your turn!</div> : '' }
            </h1>
            <h2 className="h5 mt-3 pt-3">About this Game:</h2>
            <div className="game-description" dangerouslySetInnerHTML={{__html: game.description }} />
            <Modal show={open}>

                <Modal.Body>
                    { isLoading ? 
                        <Spinner animation="border" role="status" className="ms-auto me-auto d-block mt-3 mb-3">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner> :
                        <div>
                            <h4 className="h4">Success!</h4>
                            <p>Your submission has been sent! Now just sit back and wait, you animal.</p>
                            <Button variant="secondary" onClick={_closeModal}>Close</Button>
                        </div>
                    }
                </Modal.Body>
            </Modal>

            { !game.turn ? <>
                <h3 className="h5 mt-3 pt-3 text-danger">Waiting on your partner to reply</h3>
                <p>Sit tight.</p>
            </> : <>
                <h3 className="h5 mt-3 pt-3 mb-3">Write a Letter:</h3>
                
               <Editor
                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                    onInit={(evt, editor) => editorRef.current = editor}
                    init={{
                        height: 500,
                        menubar: false,
                        toolbar: 'bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | ',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                    }} />
               <Button active variant={'primary'} className={'mt-3 mb-3'} onClick={_send}>Send! <FontAwesomeIcon icon={faPaperPlane} className='ms-1' /></Button>
               </> }
        </>
    );
}