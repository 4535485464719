import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";

import App from './components/App';

import './index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react';

import { persistor, store } from './state/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);
